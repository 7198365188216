import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledBrand = styled.div`
    text-transform: uppercase;
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 1.5625rem;
    letter-spacing: 4px;
    font-family: 'Corporate E';
    font-weight: bold;
`;

export const SmallText = styled.p<{ showAsItalic?: boolean; boldText?: boolean }>`
    font-style: ${(props) => (props.showAsItalic ? 'italic' : '')};
    font-weight: ${(props) => (props.boldText ? 'bold' : '')};
    white-space: nowrap;
`;

export const CarSaverTextGroup = styled.div`
    display: flex;
    flex-direction: column;
    line-height: ${({ theme }) => theme.typography.bodyDefault.lineHeight};
    align-items: flex-start;
`;

export const TextGroup = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    line-height: ${({ theme }) => theme.typography.bodyDefault.lineHeight};
`;

export const CarSaverFooterTextGroup = styled(TextGroup)`
    margin-bottom: 6px;
`;

export const StyledTextGroup = styled(TextGroup)`
    margin-bottom: 20px;
`;

export const CarSaverSmallLink = styled.a`
    color: inherit;
    display: block;
    text-decoration: none;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.bodyDefault.fontSize}px;
    border-bottom: 1px solid #00000033;
    line-height: 18px;
    margin-top: 2px;

    &:hover {
        text-decoration: none;
    }
`;

export const SmallLink = styled.a`
    color: inherit;
    display: block;
    text-decoration: underline;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.bodyDefault.fontSize}px;
`;

export const SmallLinkWrapper = styled.div`
    & a {
        color: black;
        display: block;
        text-decoration: none;
        cursor: pointer;
        font-size: ${({ theme }) => theme.typography.bodyDefault.fontSize}px;

        &:hover {
            text-decoration: none;
        }
    }
`;

export const ContentTitle = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-size: ${({ theme: { typography } }) => typography.footer.fontSize}px;
    font-weight: ${({ theme: { typography } }) => typography.footer.sectionHeaderFontWeight};
    letter-spacing: 0;
    margin-bottom: 1.75rem;
`;

export const OpeningHoursTitle = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-size: ${({ theme: { typography } }) => typography.footer.fontSize}px;
    font-weight: ${({ theme: { typography } }) => typography.footer.sectionHeaderFontWeight};
    margin-bottom: 0.5rem;
`;

export const StyledFooterLogo = styled.img<{ maxWidth: string }>`
    width: 100%;
    max-width: ${(props) => props.maxWidth};
    max-height: 50px;
    display: block;
    object-fit: contain;
    object-position: left center;
    margin-bottom: 30px;
`;

export const StyledBackgroundColorFill = styled.div<{ bgColor: string }>`
    width: 100%;
    height: 90px;
    position: absolute;
    left: 0;
    top: -90px;
    background-color: ${(props) => props.bgColor};
`;

export const StyledFooterShape = styled.div<{ bgColor: string }>`
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    top: -40px;
    background-color: ${(props) => props.bgColor};
`;

export const StyledSocialMediaWrapper = styled.div`
    margin-bottom: 40px;
    @media ${device.tablet} {
        margin-bottom: 32px;
    }
`;

export const StyledSocialList = styled.ul`
    list-style: none;
    display: flex;
    gap: 8px;
`;

export const StyledSocialItem = styled.li`
    display: block;
    width: 32px;
`;
