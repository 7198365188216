import { FC } from 'react';
import { Button } from '../../shared';
import { ModalWrapper } from '../../shared/modals/wrapper';
import { Footer, Wrapper } from './unknown-vehicle-make-modal.styled';
import { BookingStepsStore } from '../../../lib/state/booking/steps';
import { handleUrlClick } from '../../../utils/helpers';
import { BookingStepYourCar } from '../../../lib/api/models/umbraco';

type Props = {
    isVisible: boolean;
    closeAction: () => void;
};

const UnknownVehicleMakeModal: FC<Props> = ({ closeAction, isVisible }) => {
    const yourCarContent = BookingStepsStore.useStoreState(
        (state) => state.steps.find((step) => step.type === 'YourCar')?.content as BookingStepYourCar | undefined
    );

    return (
        <ModalWrapper cancelAction={closeAction} isVisible={isVisible} headerText={'Vi kører lige en omvej til værkstedet.'} orientation={'center'}>
            <Wrapper orientation={'center'}>
                {yourCarContent?.unsupportedMakeModalContent && (
                    <div dangerouslySetInnerHTML={{ __html: yourCarContent?.unsupportedMakeModalContent }} />
                )}
                <Footer>
                    <Button onClick={() => handleUrlClick(yourCarContent?.unsupportedMakeFormLink)} variant={'primary'}>
                        GÅ TIL ONLINE BOOKING FORMULAR
                    </Button>
                    <Button onClick={closeAction} variant={'light'}>
                        LUK
                    </Button>
                </Footer>
            </Wrapper>
        </ModalWrapper>
    );
};

export default UnknownVehicleMakeModal;
