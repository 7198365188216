import styled from 'styled-components';

export const StyledBreadcrumbList = styled.section<{ isCarsaver?: boolean }>`
    font-size: ${(props) => (props?.isCarsaver ? '14px' : '10px')};
    margin-bottom: ${(props) => (props?.isCarsaver ? '0px' : '10px')};
    min-height: 24px;
    background-color: ${(props) => (props?.isCarsaver ? 'transparent' : 'white')};
`;

export const StyledBreadCrumb = styled.a<{ isActive: boolean }>`
    text-decoration: none;

    color: #0b0b0b;
    font-size: inherit;
    font-weight: 500;
    letter-spacing: 0.42px;

    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
`;

export const StyledPageName = styled.p`
    color: #aeaeaf;
    font-size: inherit;
    letter-spacing: 0.42px;
    display: inline-block;
`;
