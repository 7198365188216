import React from 'react';
import { StyledFooterShape, Triangle } from './footer-shape.styled';

export const FooterShape = (): JSX.Element => {
    return (
        <StyledFooterShape>
            <Triangle />
        </StyledFooterShape>
    );
};
