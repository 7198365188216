import { FC, useCallback, useState } from 'react';
import { BookingFormStore } from '../../../lib/state/booking/form';
import { convertBookingStepsToInputs } from '../../../lib/state/booking/form/booking-form.helpers';
import { BookingStepsStore } from '../../../lib/state/booking/steps';
import { convertBookingStepToStepState } from '../../../lib/state/booking/steps/booking-steps.helpers';
import { StepList } from './step-list/step.list';
import { Props } from './booking-elements.props';
import { BookingWorkshopsStore } from '../../../lib/state/booking/workshops';
import { BookingContactInfoStore } from '../../../lib/state/booking/contact-info';
import { usePushError } from '../../../error-handling';
import { BookingServiceProductsStore } from '../../../lib/state/booking/service-products';
import { ErrorType } from '../../../lib/api';
import { BookingReceiptStore } from '../../../lib/state/booking/receipt';
import UnknownVehicleMakeModal from '../../booking/unknown-vehicle-make-modal/unknown-vehicle-make-modal.component';
import { useUI } from '../../ui';

const BookingElements: FC<Props> = ({ bookingSteps, siteSettings }) => {
    const { pushError } = usePushError();
    const [showUnknownMakeModal, setShowUnknownMakeModal] = useState(false);
    const ui = useUI();

    const push = useCallback(
        (error: ErrorType) => {
            if (error === 'UnSupportedVehicleMake') {
                ui.removeScrollAndLock();
                setShowUnknownMakeModal(true);
            } else pushError(error);
        },
        [pushError, ui]
    );
    const closeUnknownMakeModal = useCallback(() => {
        ui.applyScroll();
        setShowUnknownMakeModal(false);
    }, [ui]);

    return (
        <BookingStepsStore.Provider
            runtimeModel={{ currentStep: 0, steps: bookingSteps.map(convertBookingStepToStepState) ?? [] }}
            injections={(previousInjections) => ({ ...previousInjections, pushError: push })}
        >
            <BookingFormStore.Provider
                runtimeModel={{
                    inputs: bookingSteps.map(convertBookingStepsToInputs).reduce((acc, val) => [...acc, ...val], []),
                }}
                injections={(previousInjections) => ({ ...previousInjections, pushError: push })}
            >
                <BookingWorkshopsStore.Provider injections={(previousInjections) => ({ ...previousInjections, pushError: push })}>
                    <BookingServiceProductsStore.Provider injections={(previousInjections) => ({ ...previousInjections, pushError: push })}>
                        <BookingContactInfoStore.Provider injections={(previousInjections) => ({ ...previousInjections, pushError: push })}>
                            <BookingReceiptStore.Provider>
                                <StepList siteSettings={siteSettings} />
                                <UnknownVehicleMakeModal isVisible={showUnknownMakeModal} closeAction={closeUnknownMakeModal} />
                            </BookingReceiptStore.Provider>
                        </BookingContactInfoStore.Provider>
                    </BookingServiceProductsStore.Provider>
                </BookingWorkshopsStore.Provider>
            </BookingFormStore.Provider>
        </BookingStepsStore.Provider>
    );
};
export default BookingElements;
