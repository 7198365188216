import React, { VFC } from 'react';
import { BreadcrumbListContainer } from './carsaver-breadcrumb.styled';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { Breadcrumb } from '../breadcrumb';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';

type BreadcrumbCarsaverProps = {
    breadcrumbs?: Array<sharedTypes.Breadcrumb>;
    currentPageName: string;
    extraInfo?: string;
};

export const BreadcrumbCarsaverMemo: VFC<BreadcrumbCarsaverProps> = ({ breadcrumbs, currentPageName, extraInfo }) => {
    if (!currentPageName || currentPageName.trim().length === 0 || !breadcrumbs?.length) {
        return null;
    }

    return (
        <CenteredBlock>
            <BreadcrumbListContainer>
                <Breadcrumb breadcrumbs={breadcrumbs} currentPageName={currentPageName} extraInfo={extraInfo} isCarsaver />
            </BreadcrumbListContainer>
        </CenteredBlock>
    );
};

export const BreadcrumbCarsaver = React.memo(BreadcrumbCarsaverMemo);
