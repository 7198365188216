import { DeliveryType } from '../../lib/enums';
import { FC, useMemo } from 'react';
import { Wrapper } from './time-slot-picker.styled';
import { CustomerDeliveryAndPickup } from './customer-delivery-and-pickup';
import { CustomerStays } from './customer-stays';
import { VendorDelivery } from './vendor-delivery';
import { BookingStepWorkshop } from '../../lib/api/models/umbraco';
import { BookingServiceProductsStore } from '../../lib/state/booking/service-products';
import { BookingWorkshopsStore } from '../../lib/state/booking/workshops';

type Props = {
    deliveryType: DeliveryType;
    workshopContent: BookingStepWorkshop;
};

export const TimeSlotPicker: FC<Props> = ({ deliveryType, workshopContent }) => {
    const { serviceTooLong } = BookingServiceProductsStore.useStoreState((state) => state);
    const { customerHasChosenDeliveryType, selectedWorkshop: selectedWorkshopId, workshops } = BookingWorkshopsStore.useStoreState((state) => state);

    const selectedWorkshop = useMemo(() => workshops.find(({ id }) => id === selectedWorkshopId), [selectedWorkshopId, workshops]);
    const [anyTimesAvailable, anyTimesAvailableToStay] = useMemo(
        () => [
            selectedWorkshop?.availableDaysSelfDeliverAndPickup?.some((spot) => spot.timeSlots.length > 0),
            selectedWorkshop?.availableDaysToStay?.some((spot) => spot.timeSlots.length > 0),
        ],
        [selectedWorkshop?.availableDaysSelfDeliverAndPickup, selectedWorkshop?.availableDaysToStay]
    );
    const error = useMemo(() => <h4>{workshopContent.noTimeSlotsText}</h4>, [workshopContent.noTimeSlotsText]);

    const deliveryUi = useMemo(() => {
        if (serviceTooLong) {
            return <CustomerDeliveryAndPickup workshopContent={workshopContent} />;
        }

        switch (deliveryType) {
            case DeliveryType.SelfDeliverAndPickup:
                if (!anyTimesAvailable) return error;
                return customerHasChosenDeliveryType ? <CustomerDeliveryAndPickup workshopContent={workshopContent} /> : null;
            case DeliveryType.CustomerStays:
                if (!anyTimesAvailableToStay) return error;
                return customerHasChosenDeliveryType ? <CustomerStays workshopContent={workshopContent} /> : null;
            case DeliveryType.ByVendor:
                return <VendorDelivery workshopContent={workshopContent} />;
            case DeliveryType.MobileService:
                return <div dangerouslySetInnerHTML={{ __html: workshopContent.mobileServiceExplanationText ?? '' }} />;
            default:
                throw new Error('eh.spa: Invalid delivery type.');
        }
    }, [anyTimesAvailable, anyTimesAvailableToStay, customerHasChosenDeliveryType, deliveryType, error, serviceTooLong, workshopContent]);

    return deliveryUi ? <Wrapper>{deliveryUi}</Wrapper> : null;
};
