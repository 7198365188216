import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DeliveryType } from '../../../../../lib';
import { DatePicker } from '../../../../shared';
import { TimeSlotPicker } from '../../../../time-slot-picker';
import { WorkshopBookingData, DeliveryWrapper, DeliveryMethods, StyledHeader, LabelText, SubLabel, StyledRadioButton } from './booking-info.styled';
import { BookingWorkshopsStore } from '../../../../../lib/state/booking/workshops';
import { umbraco } from '../../../../../lib/api';
import { BookingFormStore } from '../../../../../lib/state/booking/form';
import { AppointmentAvailability, FuelType, TimeSlot } from '../../../../../lib/api/models/hessel-api';
import { isEqual, isSameDay } from 'date-fns';
import {
    DateStyle,
    findFirstAvailableTimeSlot,
    formatDate,
    getAvailableDaysByDeliveryType,
    getPickupTime,
    mapTimeOnlyToDuration,
} from '../../../../../utils/helpers';
import { BookingServiceProductsStore } from '../../../../../lib/state/booking/service-products';

type Props = {
    workshopContent: umbraco.BookingStepWorkshop;
    isEligibleForMobileService?: boolean;
    calendarMonthChange: (date: Date) => void;
};

const getCalendarDays = (availableDays: AppointmentAvailability<Date>[]) => {
    return availableDays?.filter(({ timeSlots }) => timeSlots.length > 0 && timeSlots.some((t) => t.available))?.map(({ date }) => date);
};

const getEstimationText = (timeSlot?: TimeSlot<Date>) => {
    const duration = timeSlot ? mapTimeOnlyToDuration(timeSlot.duration) : undefined;
    const hours = duration?.hours ?? 0;
    const minutes = duration?.minutes ?? 0;
    if (hours === 0 && minutes === 0) {
        return '-';
    }

    const minuteText = minutes > 1 ? 'minutter' : 'minut';
    const hourText = hours > 1 ? 'timer' : 'time';

    if (hours === 0) {
        return `${minutes} ${minuteText}`;
    }

    if (minutes === 0) {
        return `${hours} ${hourText}`;
    }

    return `${hours} ${hourText} og ${minutes} ${minuteText}`;
};

export const WorkshopBookingInfo: FC<Props> = ({ workshopContent, isEligibleForMobileService, calendarMonthChange }) => {
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
    const {
        selectedDate,
        selectedWorkshop,
        deliveryType,
        selectedTimeSlotToStay,
        selectedTimeSlotSelfDeliverAndPickup,
        selectedTimeSlot,
        workshops,
        customerHasChosenDeliveryType,
        selectedWorkshopAvailableDays,
    } = BookingWorkshopsStore.useStoreState((state) => state);

    const { serviceTooLong } = BookingServiceProductsStore.useStoreState((state) => state);

    const { setSelectedDate, setDeliveryType, setIsLoadingSelectedWorkshop, setCustomerHasChosenDeliveryType, setTimeSlot } =
        BookingWorkshopsStore.useStoreActions((actions) => actions);

    const vehicle = BookingFormStore.useStoreState(({ vehicle }) => vehicle);

    const workshop = useMemo(() => workshops.find(({ id }) => id === selectedWorkshop), [workshops, selectedWorkshop]);

    useEffect(() => {
        setIsLoadingSelectedWorkshop(workshop?.loadingDays ?? false);
    }, [setIsLoadingSelectedWorkshop, workshop?.loadingDays]);

    useEffect(() => {
        if (selectedTimeSlot || deliveryType === undefined) return;
        const { date, timeSlot } = findFirstAvailableTimeSlot(selectedWorkshopAvailableDays, selectedDate);
        if (date) setSelectedDate(date);
        if (timeSlot) setTimeSlot(timeSlot);
    }, [deliveryType, selectedDate, selectedTimeSlot, selectedWorkshopAvailableDays, setSelectedDate, setTimeSlot]);

    useEffect(() => {
        if (deliveryType === DeliveryType.ByVendor && vehicle?.fuelTypeId !== FuelType.EL) {
            setDeliveryType(DeliveryType.SelfDeliverAndPickup);
        }
    }, [vehicle?.fuelTypeId, deliveryType, setDeliveryType]);

    const isEligibleForVendorDelivery = useMemo(
        () => vehicle?.fuelTypeId === FuelType.EL && vehicle?.make === 'MERCEDES-BENZ',
        [vehicle?.fuelTypeId, vehicle?.make]
    );

    const onDeliveryTypeChanged = useCallback(
        (deliveryType: DeliveryType) => {
            if (!workshop) return;
            setCustomerHasChosenDeliveryType(true);
            setDeliveryType(deliveryType);
            const availableDaysInNewCalendar = getAvailableDaysByDeliveryType(workshop, deliveryType);
            const sameDateInNewCalendar = availableDaysInNewCalendar.find((x) => isSameDay(x.date, selectedDate));
            const firstAvailableDateInNewCalendar = availableDaysInNewCalendar.find((x) => x.timeSlots.some((timeSlot) => timeSlot.available));

            const dateToSelect = sameDateInNewCalendar ?? firstAvailableDateInNewCalendar;
            if (dateToSelect) setSelectedDate(dateToSelect.date);
        },
        [selectedDate, setCustomerHasChosenDeliveryType, setDeliveryType, setSelectedDate, workshop]
    );

    const onNewDatePicked = useCallback(
        (pickedDate: Date) => {
            if (selectedDate && isEqual(pickedDate, selectedDate)) return;
            setSelectedDate(pickedDate);

            const availableDay = selectedWorkshopAvailableDays.find((x) => isSameDay(x.date, pickedDate));
            if (!availableDay) return;

            const sameTimeSlotOnNewDay = selectedTimeSlot?.startDateTime
                ? availableDay.timeSlots.find(
                      (timeSlot) =>
                          timeSlot.available &&
                          timeSlot.startDateTime &&
                          formatDate(timeSlot.startDateTime, DateStyle.HH_mm) === formatDate(selectedTimeSlot.startDateTime, DateStyle.HH_mm)
                  )
                : undefined;

            const firstAvailableTimeSlot = availableDay.timeSlots.find((timeSlot) => timeSlot.available);
            const timeSlotToSelect = sameTimeSlotOnNewDay ?? firstAvailableTimeSlot;

            if (timeSlotToSelect) setTimeSlot(timeSlotToSelect);
        },
        [selectedDate, selectedTimeSlot?.startDateTime, selectedWorkshopAvailableDays, setSelectedDate, setTimeSlot]
    );

    const calendarDays = useMemo(() => getCalendarDays(selectedWorkshopAvailableDays), [selectedWorkshopAvailableDays]);

    return (
        <WorkshopBookingData isLoading={!isDatePickerModalOpen && (workshop?.loadingDays || false)}>
            <DatePicker
                selectedDate={selectedDate}
                onSelect={onNewDatePicked}
                availableDays={calendarDays}
                modalHeaderText={workshopContent.modalHeaderText}
                modalConfirmText={workshopContent.modalConfirmText}
                modalCancelText={workshopContent.modalCancelText}
                onMonthChange={calendarMonthChange}
                toggleModalNotification={setIsDatePickerModalOpen}
                loadingDays={workshop?.loadingDays || false}
                showWeekNumber={true}
                includeWeekends={true}
            />
            <DeliveryWrapper>
                <DeliveryMethods>
                    <StyledHeader>
                        {serviceTooLong ? workshopContent.serviceTooLongHeader ?? '' : workshopContent.deliveryAndPickupHeader}
                    </StyledHeader>

                    {serviceTooLong ? (
                        <div dangerouslySetInnerHTML={{ __html: workshopContent.serviceTooLongExplanation }}></div>
                    ) : (
                        <>
                            <StyledRadioButton
                                id="rbCustomerStays"
                                groupName="deliveryType"
                                value={`${DeliveryType.CustomerStays}`}
                                action={() => onDeliveryTypeChanged(DeliveryType.CustomerStays)}
                                checked={deliveryType === DeliveryType.CustomerStays && customerHasChosenDeliveryType}
                                labelAlignment="top"
                            >
                                <LabelText>{workshopContent.customerStaysLabel}</LabelText>
                                <SubLabel>
                                    <small>
                                        <b>
                                            {workshopContent.customerStaysSubLabel}: {getEstimationText(selectedTimeSlotToStay)}
                                        </b>
                                    </small>
                                </SubLabel>
                            </StyledRadioButton>

                            <StyledRadioButton
                                id="rbSelfDeliveryAndPickup"
                                groupName="deliveryType"
                                value={`${DeliveryType.SelfDeliverAndPickup}`}
                                action={() => onDeliveryTypeChanged(DeliveryType.SelfDeliverAndPickup)}
                                checked={deliveryType === DeliveryType.SelfDeliverAndPickup && customerHasChosenDeliveryType}
                                labelAlignment="top"
                            >
                                <LabelText>{workshopContent.customerSelfDeliveryAndPickup}</LabelText>
                                <SubLabel>
                                    <small>
                                        <b>
                                            {selectedTimeSlotSelfDeliverAndPickup &&
                                                workshopContent.customerSelfDeliveryAndPickupSubLabel &&
                                                `${workshopContent.customerSelfDeliveryAndPickupSubLabel} ${formatDate(
                                                    getPickupTime(selectedTimeSlotSelfDeliverAndPickup.startDateTime),
                                                    DateStyle.HH_mm
                                                )}`}
                                        </b>
                                    </small>
                                </SubLabel>
                            </StyledRadioButton>

                            {isEligibleForVendorDelivery && (
                                <StyledRadioButton
                                    id="rbByVendor"
                                    groupName="deliveryType"
                                    value={`${DeliveryType.ByVendor}`}
                                    action={() => onDeliveryTypeChanged(DeliveryType.ByVendor)}
                                    checked={deliveryType === DeliveryType.ByVendor && customerHasChosenDeliveryType}
                                    labelAlignment="top"
                                >
                                    <LabelText>{workshopContent.byVendorLabel}</LabelText>
                                </StyledRadioButton>
                            )}

                            {isEligibleForMobileService &&
                                workshopContent.mobileServiceReadMoreLink &&
                                workshopContent.mobileServiceRadioButtonLabel && (
                                    <StyledRadioButton
                                        id="rbMobileService"
                                        groupName="deliveryType"
                                        value={`${DeliveryType.MobileService}`}
                                        action={() => onDeliveryTypeChanged(DeliveryType.MobileService)}
                                        checked={deliveryType === DeliveryType.MobileService && customerHasChosenDeliveryType}
                                        labelAlignment="top"
                                    >
                                        <LabelText>
                                            {workshopContent.mobileServiceRadioButtonLabel} -{' '}
                                            <a href={workshopContent.mobileServiceReadMoreLink.url} target="_blank">
                                                Læs mere her
                                            </a>
                                        </LabelText>
                                    </StyledRadioButton>
                                )}
                        </>
                    )}
                </DeliveryMethods>

                <TimeSlotPicker deliveryType={deliveryType} workshopContent={workshopContent} />
            </DeliveryWrapper>
        </WorkshopBookingData>
    );
};
