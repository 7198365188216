import styled from 'styled-components';
import { MINIMAL_SITE_HEADER_HEIGHT } from '../../../lib/enums';
import { device } from '../../../lib/media-query';

export const BreadcrumbListContainer = styled.div`
    padding-top: ${MINIMAL_SITE_HEADER_HEIGHT.MOBILE}px;

    @media ${device.tablet} {
        padding-top: ${MINIMAL_SITE_HEADER_HEIGHT.DESKTOP}px;
    }
`;
