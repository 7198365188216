import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Separator, SimpleModal, WorkshopCard } from '../../../shared';
import {
    StyledStep,
    StepHeader,
    WorkshopListWrapper,
    ModalContent,
    CommentAccordionWrapper,
    CommentWrapper,
    AccordionHeader,
} from './workshop.styled';
import { BookingWorkshopsStore } from '../../../../lib/state/booking/workshops';
import { umbraco } from '../../../../lib/api';
import { BookingStepsStore } from '../../../../lib/state/booking/steps';
import { WorkshopBookingInfo } from './booking-info';
import { AvailableDaysParam, Workshop } from '../../../../lib/state/booking/workshops/booking-workshops.types';
import { add, addMonths, lastDayOfMonth, sub } from 'date-fns';
import { useGTMBookingData } from '../../../../hooks/booking/booking/use-gtm-booking-data';
import { BookingFormStore } from '../../../../lib/state/booking/form';
import { AppointmentAvailabilityParams } from '../../../../lib/api/models/hessel-api';
import { BookingServiceProductsStore } from '../../../../lib/state/booking/service-products';
import { useUI } from '../../../ui';
import { DateStyle, formatDate, getRemainingDaysInMonth } from '../../../../utils/helpers/date.helper';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { findFirstAvailableTimeSlot, getAvailableDaysByDeliveryType, getLeadTimeForStore } from '../../../../utils/helpers/booking/workshop.helper';
import { Accordion } from '../../../shared/accordion';
import { theme } from '../../../../themes/default-theme';
import { Comment } from '../services/comment';
import { filterStepType } from '../../../../lib/state/booking/steps/booking-steps.helpers';
import { DeliveryType } from '../../../../lib';

type Props = {
    isCompleted: boolean;
    workshopContent: umbraco.BookingStepWorkshop;
    stepNumber: number;
};

type LatestAppointmentAvailabilityParams = Pick<AppointmentAvailabilityParams, 'make' | 'serviceProductIds' | 'vehicleClass'>;

type ExtraCalendarFetch = {
    id: number;
    startDate: Date;
};

export const BookingWorkshop: FC<Props> = ({ workshopContent, stepNumber }) => {
    const [params, setParams] = useState<LatestAppointmentAvailabilityParams>();
    const [remainingWorkshopsLoadStarted, setRemainingWorkshopsLoadStarted] = useState(false);
    const [nearbyStoresShouldUpdate, setNearbyStoresShouldUpdate] = useState(false);
    const [commentOpened, setCommentOpened] = useState(false);

    const {
        workshops,
        selectedWorkshop,
        workshopStepIsValid,
        favouriteWorkshop,
        isLoadingSelectedWorkshop,
        calendarShouldUpdate,
        shopToGetNearbyFrom,
        selectedDate,
        deliveryType,
    } = BookingWorkshopsStore.useStoreState((state) => state);

    const {
        setSelectedWorkshop,
        getAvailableDaysThunk,
        getNearbyStoresAvailableDaysThunk,
        getNonPreferredStoreAvailableDaysThunk,
        setCalendarShouldUpdate,
        setShopToGetNearbyFrom,
        clearSelectedTimeSlots,
        setSelectedDate,
        setTimeSlotForDeliveryType,
    } = BookingWorkshopsStore.useStoreActions((actions) => actions);

    const { vehicle, preferredWorkshopId } = BookingFormStore.useStoreState(({ vehicle }) => ({
        vehicle,
        preferredWorkshopId: vehicle?.preferredWorkshopId,
    }));

    const { selectedServiceProductsIds, serviceTooLong, availableServiceProducts } = BookingServiceProductsStore.useStoreState((state) => state);

    const { currentStep, productIdsForAddTirehotel } = BookingStepsStore.useStoreState(({ steps, currentStep }) => ({
        productIdsForAddTirehotel: steps
            .filter(filterStepType('Services'))
            .find(() => true)
            ?.content.tirehotelProductIds?.map(({ productId }) => productId),
        currentStep,
    }));
    const { setStepValidity } = BookingStepsStore.useStoreActions((actions) => actions);

    const nearbyStores = useMemo(
        () => workshops.find(({ id }) => id === shopToGetNearbyFrom)?.nearByStores.map(({ id }) => id) ?? [],
        [workshops, shopToGetNearbyFrom]
    );

    const closestStores = useMemo(() => {
        return [shopToGetNearbyFrom, ...nearbyStores]
            .map((id) => workshops.find((workshop) => workshop.id === id))
            .filter((val): val is Workshop => (val ? true : false));
    }, [workshops, shopToGetNearbyFrom, nearbyStores]);

    const [extraCalendarFetches, setExtraCalendarFetches] = useState<ExtraCalendarFetch[]>([]);

    const servicesToSend = useMemo(() => {
        const selectedProducts = availableServiceProducts.filter((product) => selectedServiceProductsIds.includes(product.id));
        const productsWithOptions = selectedProducts.filter((product) => (product.productOptions?.length ?? 0) > 0);
        return selectedServiceProductsIds
            .filter((id) => !productsWithOptions.some((option) => option.id === id))
            .filter((id) => !productIdsForAddTirehotel?.includes(id));
    }, [availableServiceProducts, productIdsForAddTirehotel, selectedServiceProductsIds]);

    const isEligibleForMobileService = useCallback(
        (workshopId: number) => {
            const selectedWorkshopSupportsMobileService = selectedWorkshop
                ? workshopContent.departmentsSupportingMobileService?.map((x) => x.sabId).includes(workshopId)
                : false;

            return (
                !serviceTooLong &&
                vehicle?.make === 'MERCEDES-BENZ' &&
                vehicle?.vehicleClass === 'CAR' &&
                selectedWorkshopSupportsMobileService === true
            );
        },
        [selectedWorkshop, serviceTooLong, vehicle?.make, vehicle?.vehicleClass, workshopContent.departmentsSupportingMobileService]
    );

    const getAvailableDays = useCallback(
        async (
            params: Pick<AppointmentAvailabilityParams, 'make' | 'serviceProductIds' | 'vehicleClass'>,
            extraDaysStartDate?: Date,
            remainingWorkshops = false,
            onlyLoadNearbyStores?: boolean
        ) => {
            if (favouriteWorkshop) {
                const today = new Date();
                const daysRemainingInMonth = getRemainingDaysInMonth(today);
                const finalDayOfMonth = add(today, { days: daysRemainingInMonth });

                const workshopId = selectedWorkshop ?? favouriteWorkshop;

                const baseParam: AvailableDaysParam = {
                    apiPayload: {
                        ...params,
                        workshopId: workshopId,
                        serviceProductIds: servicesToSend,
                        dateFrom: formatDate(today, DateStyle.yyyy_mm_dd),
                        dateTo: formatDate(add(finalDayOfMonth, { months: 2 }), DateStyle.yyyy_mm_dd),
                        customerStays: false,
                    },
                    serviceTooLong: serviceTooLong,
                    serviceTooLongLeadTime: (id) => getLeadTimeForStore(workshopContent, id),
                    serviceTooLongHolidays: workshopContent.serviceTooLongHolidays ?? [],
                    mobileService: isEligibleForMobileService,
                };

                if (extraDaysStartDate && selectedWorkshop) {
                    await getAvailableDaysThunk({
                        ...baseParam,
                        apiPayload: {
                            ...baseParam.apiPayload,
                            customerStays: false,
                            dateFrom: formatDate(extraDaysStartDate, DateStyle.yyyy_mm_dd),
                            dateTo: formatDate(add(extraDaysStartDate, { months: 3 })),
                            workshopId: workshopId,
                        },
                    });
                } else if (remainingWorkshops) {
                    //This is the load of the remaining workshops when opening the modal with all workshops
                    // Rest of workshops
                    getNonPreferredStoreAvailableDaysThunk({
                        ...baseParam,
                        apiPayload: {
                            ...baseParam.apiPayload,
                            customerStays: false,
                        },
                    });
                } else if (onlyLoadNearbyStores) {
                    // This loads the new nearby stores when the user changes workshop (it will only load not already loaded stores)
                    getNearbyStoresAvailableDaysThunk({
                        ...baseParam,
                        apiPayload: {
                            ...baseParam.apiPayload,
                            customerStays: false,
                        },
                    });
                } else {
                    //This is the initial load of workshops when entering the workshops step
                    // Preferred Store
                    await getAvailableDaysThunk({
                        ...baseParam,
                        fullReload: true,
                        apiPayload: {
                            ...baseParam.apiPayload,
                            customerStays: false,
                        },
                    });

                    // Preferred Store: Nearby Stores (We intentionally DO NOT await this call to speed up the process)
                    getNearbyStoresAvailableDaysThunk({
                        ...baseParam,
                        apiPayload: {
                            ...baseParam.apiPayload,
                            customerStays: false,
                        },
                    });
                }
            }
        },
        [
            favouriteWorkshop,
            getAvailableDaysThunk,
            getNearbyStoresAvailableDaysThunk,
            getNonPreferredStoreAvailableDaysThunk,
            isEligibleForMobileService,
            selectedWorkshop,
            serviceTooLong,
            servicesToSend,
            workshopContent,
        ]
    );

    const onCalenderMonthChange = (date: Date) => {
        const today = new Date();
        const finalMonth = addMonths(today, 2);
        const finalDay = lastDayOfMonth(finalMonth);

        if (date > finalDay && selectedWorkshop) {
            const calendarFetch = extraCalendarFetches.find((x) => x.id === selectedWorkshop);
            if (!calendarFetch || calendarFetch.startDate < date) {
                const cleanedArray = extraCalendarFetches.reduce((prev, x) => {
                    if (x.id !== selectedWorkshop) {
                        return [x, ...prev];
                    }
                    return prev;
                }, Array<ExtraCalendarFetch>());
                setExtraCalendarFetches([{ id: selectedWorkshop, startDate: sub(add(date, { months: 3 }), { days: 1 }) }, ...cleanedArray]);
                getAvailableDays(
                    {
                        make: vehicle?.make ?? '',
                        vehicleClass: vehicle?.vehicleClass ?? '',
                        serviceProductIds: selectedServiceProductsIds,
                    },
                    date
                );
            }
        }
    };

    useEffect(() => {
        if (
            currentStep === stepNumber &&
            (params?.make !== vehicle?.make ||
                params?.vehicleClass !== vehicle?.vehicleClass ||
                params?.serviceProductIds !== selectedServiceProductsIds ||
                calendarShouldUpdate) &&
            workshops.every((x) => !x.loadingDays)
        ) {
            setCalendarShouldUpdate(false);
            setParams({
                make: vehicle?.make ?? '',
                vehicleClass: vehicle?.vehicleClass ?? '',
                serviceProductIds: selectedServiceProductsIds,
            });
            getAvailableDays({
                make: vehicle?.make ?? '',
                vehicleClass: vehicle?.vehicleClass ?? '',
                serviceProductIds: selectedServiceProductsIds,
            });
        }
    }, [
        calendarShouldUpdate,
        currentStep,
        getAvailableDays,
        params?.make,
        params?.serviceProductIds,
        params?.vehicleClass,
        selectedServiceProductsIds,
        setCalendarShouldUpdate,
        stepNumber,
        vehicle?.vehicleClass,
        vehicle?.make,
        workshops,
    ]);

    useEffect(() => {
        if (currentStep === stepNumber) {
            setStepValidity({ isValid: workshopStepIsValid || serviceTooLong, stepNumber });
        }
    }, [currentStep, workshopStepIsValid, setStepValidity, stepNumber, workshops, serviceTooLong]);

    useEffect(() => {
        if (currentStep === stepNumber && shopToGetNearbyFrom === undefined) {
            setShopToGetNearbyFrom(selectedWorkshop);
        }
    }, [currentStep, selectedWorkshop, setShopToGetNearbyFrom, shopToGetNearbyFrom, stepNumber]);

    useEffect(() => {
        if (currentStep !== stepNumber && remainingWorkshopsLoadStarted) {
            setRemainingWorkshopsLoadStarted(false);
        }
    }, [currentStep, remainingWorkshopsLoadStarted, stepNumber]);

    const [showWorkshops, setShowWorkshops] = useState(false);

    const ui = useUI();

    const { trackBooking } = useGtmTracking();
    const tracker = trackBooking();
    const gtmData = useGTMBookingData();

    useEffect(() => {
        if (currentStep === stepNumber) {
            tracker.checkoutStep(3, [...(gtmData?.products ?? [])]);
        }
    }, [currentStep, gtmData?.products, gtmData?.workshop.name, stepNumber, tracker]);

    useEffect(() => {
        if (!nearbyStoresShouldUpdate) return;
        setNearbyStoresShouldUpdate(false);
        getAvailableDays(
            {
                make: vehicle?.make ?? '',
                vehicleClass: vehicle?.vehicleClass ?? '',
                serviceProductIds: selectedServiceProductsIds,
            },
            undefined,
            false,
            true
        );
    }, [vehicle?.vehicleClass, vehicle?.make, getAvailableDays, selectedServiceProductsIds, nearbyStoresShouldUpdate]);

    const selectInitialTimeSlot = useCallback(
        (deliveryType: DeliveryType, workshopId) => {
            const workshop = workshops.find((x) => x.id === workshopId);
            if (!workshop) return;
            const { date, timeSlot } = findFirstAvailableTimeSlot(getAvailableDaysByDeliveryType(workshop, deliveryType), selectedDate);
            if (date) setSelectedDate(date);
            if (timeSlot)
                setTimeSlotForDeliveryType({
                    deliveryType,
                    timeSlot,
                });
        },
        [selectedDate, setSelectedDate, setTimeSlotForDeliveryType, workshops]
    );

    useEffect(() => {
        if (stepNumber !== currentStep || selectedWorkshop === undefined) return;

        selectInitialTimeSlot(DeliveryType.CustomerStays, selectedWorkshop);
        selectInitialTimeSlot(DeliveryType.SelfDeliverAndPickup, selectedWorkshop);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, stepNumber]);

    if (!selectedWorkshop) {
        return null;
    }

    return (
        <>
            <StyledStep>
                <StepHeader>
                    <h3>{workshopContent.titleText}</h3>

                    <Button
                        onClick={() => {
                            if (!remainingWorkshopsLoadStarted) {
                                setRemainingWorkshopsLoadStarted(true);
                                getAvailableDays(
                                    {
                                        make: vehicle?.make ?? '',
                                        vehicleClass: vehicle?.vehicleClass ?? '',
                                        serviceProductIds: selectedServiceProductsIds,
                                    },
                                    undefined,
                                    true
                                );
                            }
                            setShowWorkshops(true);
                            ui.removeScroll();
                        }}
                        variant="link"
                    >
                        {workshopContent.openAllWorkshopsButtonText}
                    </Button>
                </StepHeader>
                <WorkshopListWrapper>
                    {closestStores.map((x) => {
                        return (
                            <WorkshopCard
                                key={x?.id}
                                workshop={x}
                                isSelected={selectedWorkshop === x.id}
                                isLoading={x.loadingDays}
                                setAsSelected={(selected) => {
                                    setSelectedWorkshop({ id: selected });
                                    setShopToGetNearbyFrom(x.id);
                                    setNearbyStoresShouldUpdate(true);

                                    clearSelectedTimeSlots();
                                    selectInitialTimeSlot(DeliveryType.CustomerStays, x.id);
                                    selectInitialTimeSlot(DeliveryType.SelfDeliverAndPickup, x.id);
                                }}
                                disabled={deliveryType === DeliveryType.MobileService && !isEligibleForMobileService(x.id)}
                                lastVisited={preferredWorkshopId === x.id}
                                workshopContent={workshopContent}
                            />
                        );
                    })}
                </WorkshopListWrapper>

                <Separator marginTop="1.5rem" marginBottom="1.5rem" />

                <WorkshopBookingInfo
                    workshopContent={workshopContent}
                    isEligibleForMobileService={isEligibleForMobileService(selectedWorkshop)}
                    calendarMonthChange={(date) => onCalenderMonthChange(date)}
                />

                {workshopContent.collapsibleCommentText && (
                    <CommentAccordionWrapper isLoading={isLoadingSelectedWorkshop}>
                        <Accordion
                            border={'none'}
                            backgroundColor={theme.palette.common.white}
                            customTitle={<AccordionHeader>{workshopContent.collapsibleCommentText}</AccordionHeader>}
                            title={''}
                            variant="primary"
                            isOpen={commentOpened}
                            alignCenter={true}
                            onClick={() => setCommentOpened((isOpened) => !isOpened)}
                            index={0}
                        >
                            <CommentWrapper>
                                <Comment />
                            </CommentWrapper>
                        </Accordion>
                    </CommentAccordionWrapper>
                )}
            </StyledStep>

            <SimpleModal
                headerText={workshopContent.allWorkshopsDialogHeaderText}
                isVisible={showWorkshops}
                closeText={workshopContent.allWorkshopsDialogCloseText}
                closeAction={() => {
                    setShowWorkshops(false);
                    ui.applyScroll();
                }}
            >
                <ModalContent>
                    {workshops.map((x) => {
                        return (
                            <WorkshopCard
                                key={`modal-${x.id}`}
                                workshop={x}
                                isSelected={x.id === selectedWorkshop}
                                setAsSelected={(selected) => {
                                    setSelectedWorkshop({ id: selected });
                                    setShowWorkshops(false);
                                    setShopToGetNearbyFrom(x.id);

                                    clearSelectedTimeSlots();
                                    selectInitialTimeSlot(DeliveryType.CustomerStays, x.id);
                                    selectInitialTimeSlot(DeliveryType.SelfDeliverAndPickup, x.id);

                                    ui.applyScroll();
                                }}
                                disabled={deliveryType === DeliveryType.MobileService && !isEligibleForMobileService(x.id)}
                                isLoading={x.loadingDays}
                                lastVisited={preferredWorkshopId === x.id}
                                workshopContent={workshopContent}
                            />
                        );
                    })}
                </ModalContent>
            </SimpleModal>
        </>
    );
};
