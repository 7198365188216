import { useEffect, useMemo, useState } from 'react';
import { DeliveryType } from '../../../lib';
import { hesselApi } from '../../../lib/api';
import { BookingContactInfoStore } from '../../../lib/state/booking/contact-info';
import { BookingFormStore } from '../../../lib/state/booking/form';
import { generateFormId } from '../../../lib/state/booking/form/booking-form.helpers';
import { BookingServiceProductsStore } from '../../../lib/state/booking/service-products';
import { BookingStepsStore } from '../../../lib/state/booking/steps';
import { filterStepType } from '../../../lib/state/booking/steps/booking-steps.helpers';
import { BookingWorkshopsStore } from '../../../lib/state/booking/workshops';
import { DateStyle, formatDate, formInputValue, removeDuplicateProducts } from '../../../utils/helpers';

export const useBookAppointmentData = (): hesselApi.BookServiceAppointment | undefined => {
    const [data, setData] = useState<hesselApi.BookServiceAppointment>();

    const { isTermsAccepted } = BookingContactInfoStore.useStoreState((state) => state);
    const { vehicle, inputs } = BookingFormStore.useStoreState((state) => state);
    const { comment, selectedServiceProductsIds, serviceTooLong, flattenedProductsAndSubProducts, flattenedProductsOptions } =
        BookingServiceProductsStore.useStoreState((state) => state);
    const { steps } = BookingStepsStore.useStoreState((state) => state);
    const { deliveryType, rentalCar, ownRisk, selectedTimeSlot, selectedWorkshop } = BookingWorkshopsStore.useStoreState((state) => state);
    const { city, postalCode, streetName, email, mobile } = BookingContactInfoStore.useStoreState((state) => state.contactInfo);

    const { fullName, mileage, licensePlate } = useMemo(() => {
        const mileageInput = inputs.find(({ id }) => id === generateFormId('Services', 'Driven Kilometers'));

        const nameInput = inputs.find(({ id }) => id === generateFormId('ContactInfo', 'Name'));
        const fullName = nameInput ? formInputValue(nameInput) : '';
        const mileage = mileageInput ? Number(formInputValue(mileageInput)) : undefined;
        const licensePlateInput = inputs.find((s) => s.id === generateFormId('YourCar', 'Car Registration Number'));

        return {
            fullName,
            mileage: mileage === 0 ? undefined : mileage,
            licensePlate: licensePlateInput ? formInputValue(licensePlateInput) : null,
        };
    }, [inputs]);

    const productIdsToSubmit: string[] = useMemo(() => {
        const selectedProductsWithoutOptions = removeDuplicateProducts(flattenedProductsAndSubProducts) // This is necessary because "Dækopbevaring" is a duplicated product
            .filter((product) => (product.productOptions ?? []).length === 0)
            .filter(({ id }) => selectedServiceProductsIds.includes(id));

        const selectedOptions = flattenedProductsOptions.filter(({ id }) => selectedServiceProductsIds.includes(id));

        return selectedProductsWithoutOptions.concat(selectedOptions).map((product) => product.id);
    }, [flattenedProductsAndSubProducts, flattenedProductsOptions, selectedServiceProductsIds]);

    const productIdsForAddTirehotel = useMemo(
        () =>
            steps
                .filter(filterStepType('Services'))
                .find(() => true)
                ?.content.tirehotelProductIds?.map(({ productId }) => productId),
        [steps]
    );

    const consentCode = useMemo(
        () => steps.map((step) => (step.type === 'ContactInfo' ? step.content.consentCode : 0)).find((content) => (content ? true : false)),
        [steps]
    );

    useEffect(() => {
        setData({
            customer: {
                city,
                postalCode,
                streetName,
                email,
                mobile,
                fullName,
            },
            marketingConsent: isTermsAccepted ?? false,
            consentCode: consentCode?.toString() ?? '',
            customerNotes: comment,
            dueInDateTime: formatDate(selectedTimeSlot?.startDateTime ?? new Date(), DateStyle.booking_format),
            bookingOptions: {
                customerRentalCar: rentalCar ?? false,
                rentalCarInsurance: ownRisk ? true : false,
                customerStays: deliveryType === DeliveryType.CustomerStays,
                pickUpCustomerCar: deliveryType === DeliveryType.ByVendor,
                isMobileService: deliveryType === DeliveryType.MobileService,
                serviceTooLong: serviceTooLong,
                customerBringsTires: !vehicle?.hasStoredTires,
                addTirehotelToOrder: selectedServiceProductsIds.some((product) => productIdsForAddTirehotel?.includes(product)),
            },
            serviceProductIds: productIdsToSubmit.filter((product) => !productIdsForAddTirehotel?.includes(product)),
            workshopId: Number(selectedWorkshop ?? '0'),
            vehicle: {
                class: vehicle?.vehicleClass ?? '',
                licensePlate: licensePlate ?? '',
                make: vehicle?.make ?? '',
                mileage,
            },
        });
    }, [
        city,
        comment,
        consentCode,
        deliveryType,
        email,
        fullName,
        isTermsAccepted,
        licensePlate,
        mileage,
        mobile,
        ownRisk,
        postalCode,
        productIdsForAddTirehotel,
        productIdsToSubmit,
        rentalCar,
        selectedServiceProductsIds,
        selectedTimeSlot?.startDateTime,
        selectedWorkshop,
        serviceTooLong,
        streetName,
        vehicle?.hasStoredTires,
        vehicle?.make,
        vehicle?.vehicleClass,
    ]);

    return data;
};
