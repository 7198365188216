import styled, { css } from 'styled-components';
import { device } from '../../../lib/media-query';

export const WrapperItemWrapper = styled.div<{ isSelected: boolean; disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => (props.isSelected ? props.theme.palette.common.white : props.theme.palette.grey.main)};
    box-shadow: ${(props) => (props.isSelected ? props.theme.shadows.large : 'none')};
    width: 100%;
    padding: 5px 10px;
    height: 95px;

    min-width: 150px;

    @media (max-width: 500px) {
        min-width: 202px;
    }

    padding-bottom: 10px;
    cursor: ${(props) => (props.isSelected || props.disabled ? 'default' : 'pointer')};
    ${({ disabled }) =>
        disabled &&
        css`
            user-select: none;
            color: #848484;
        `}

    border-bottom: ${(props) => (props.isSelected ? '4px solid ' + props.theme.palette.primary.main : 'none')};

    @media ${device.mobileL} {
        height: 105px;
    }
`;

export const WorkshopHeader = styled.div`
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    align-items: baseline;
`;

export const WorkshopName = styled.h4<{ disabled?: boolean }>`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    ${(props) => props.disabled && 'color: #848484;'}
`;
export const DisabledText = styled.p`
    line-height: 16px;
    font-size: 10px;

    @media ${device.mobileL} {
        font-size: 12px;
    }
`;

export const LatestVisit = styled.div`
    display: flex;
    align-items: center;
`;

export const LatestVisitText = styled.p`
    color: #688488;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 17px;
`;

export const WorkshopBody = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 1rem;

    @media ${device.mobileL} {
        display: flex;
        flex-direction: column;
    }
`;

export const WorkshopAddress = styled.p`
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 16px;

    @media ${device.mobileL} {
        margin-bottom: 5px;
        max-width: 100px;
    }
`;

export const DateWrapper = styled.div`
    justify-self: center;

    @media ${device.mobileL} {
        display: flex;
        align-items: baseline;
    }
`;

export const DateHeader = styled.p`
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 16px;

    @media ${device.mobileL} {
        margin-right: 5px;
    }
`;

export const DateInfo = styled.p`
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
`;
