import { action, computed, StateMapper, thunk } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import { WorkshopsInjections, WorkshopsActions, WorkshopsState, WorkshopsStore, WorkshopsThunks, Workshop } from './booking-workshops.types';
import { add, parseISO } from 'date-fns';
import { DeliveryType } from '../../../enums';
import { getWorkshops, getWorkshopServiceAppointmentAvailability } from '../../../api';
import { AppointmentAvailability, SetAvailableDaysParams, TimeSlot } from '../../../api/models/hessel-api';
import { generateServiceTooLongAppointmentAvailability, getAvailableDaysByDeliveryType } from '../../../../utils/helpers/booking/workshop.helper';
import { DateStyle, formatDate } from '../../../../utils/helpers';

const addDaysToWorkshop = (params: SetAvailableDaysParams, existingTimeSlots: AppointmentAvailability<Date>[]): AppointmentAvailability<Date>[] => {
    const daysToAddMapped = params.availableDays.map(({ date, timeSlots }) => ({
        date: parseISO(date),
        timeSlots: timeSlots.map(({ startDateTime, ...rest }) => ({
            ...rest,
            startDateTime: parseISO(startDateTime),
        })),
    }));
    if (params.concatToExistingDays) {
        return [...daysToAddMapped, ...existingTimeSlots];
    } else {
        return [...daysToAddMapped];
    }
};

const setTimeSlotForDeliveryType = (state: StateMapper<WorkshopsState>, deliveryType: DeliveryType, timeSlot: TimeSlot<Date> | undefined) => {
    if (deliveryType === DeliveryType.SelfDeliverAndPickup) state.selectedTimeSlotSelfDeliverAndPickup = timeSlot;
    else if (deliveryType === DeliveryType.MobileService) state.selectedTimeSlotMobileService = timeSlot;
    else if (deliveryType === DeliveryType.ServiceTooLong) state.selectedTimeSlotServiceTooLong = timeSlot;
    else if (deliveryType === DeliveryType.CustomerStays || deliveryType === DeliveryType.ByVendor) state.selectedTimeSlotToStay = timeSlot;
};

const bookingWorkshopDefaultState = (): WorkshopsState => ({
    deliveryType: DeliveryType.CustomerStays,
    customerHasChosenDeliveryType: false,
    loadingWorkshops: false,
    selectedDate: new Date(),
    workshops: [],
    workshopStepIsValid: computed((state) => {
        if (state.deliveryType === DeliveryType.ByVendor) return true;

        if (state.selectedTimeSlot === undefined) return false;
        return (state.selectedWorkshop && state.selectedDate && state.customerHasChosenDeliveryType) === true;
    }),
    calendarShouldUpdate: false,
    isLoadingSelectedWorkshop: false,
    selectedTimeSlot: computed((state) => {
        if (state.deliveryType === DeliveryType.SelfDeliverAndPickup) return state.selectedTimeSlotSelfDeliverAndPickup;
        if (state.deliveryType === DeliveryType.MobileService) return state.selectedTimeSlotMobileService;
        if (state.deliveryType === DeliveryType.ServiceTooLong) return state.selectedTimeSlotServiceTooLong;
        if (state.deliveryType === DeliveryType.CustomerStays || state.deliveryType === DeliveryType.ByVendor) return state.selectedTimeSlotToStay;
    }),
    selectedWorkshopAvailableDays: computed((state) => {
        const workshop = state.workshops.find((x) => x.id === state.selectedWorkshop);
        if (!workshop) return [];
        return getAvailableDaysByDeliveryType(workshop, state.deliveryType);
    }),
});

const bookingWorkshopActions = (): WorkshopsActions => ({
    setAvailableDays: action((state, payload) => {
        payload.params.forEach((param) => {
            const workshopToModify = state.workshops.find((workshop) => workshop.id === param.id);
            if (!workshopToModify) return;
            switch (payload.deliveryType) {
                case DeliveryType.SelfDeliverAndPickup:
                    workshopToModify.availableDaysSelfDeliverAndPickup = addDaysToWorkshop(param, workshopToModify.availableDaysSelfDeliverAndPickup);
                    break;
                case DeliveryType.MobileService:
                    workshopToModify.availableDaysMobileService = addDaysToWorkshop(param, workshopToModify.availableDaysMobileService);
                    break;
                case DeliveryType.ServiceTooLong:
                    workshopToModify.availableDaysServiceTooLong = addDaysToWorkshop(param, workshopToModify.availableDaysServiceTooLong);
                    break;
                case DeliveryType.CustomerStays:
                case DeliveryType.ByVendor:
                    workshopToModify.availableDaysToStay = addDaysToWorkshop(param, workshopToModify.availableDaysToStay);
                    break;
            }
        });
    }),
    setDeliveryType: action((state, payload) => {
        state.deliveryType = payload;
    }),
    setCustomerHasChosenDeliveryType: action((state, payload) => {
        state.customerHasChosenDeliveryType = payload;
    }),
    setLoadingWorkshops: action((state, payload) => {
        state.loadingWorkshops = payload;
    }),
    setRentalCar: action((state, payload) => {
        state.rentalCar = payload;

        // OwnRisk is dependant on whether RentalCar is selected.
        state.ownRisk = payload;
    }),
    setOwnRisk: action((state, payload) => {
        state.ownRisk = payload;
    }),
    setSelectedDate: action((state, payload) => {
        state.selectedDate = payload;
    }),
    setSelectedWorkshop: action((state, payload) => {
        const workshopIsInWorkshopsList = state.workshops.some((workshop) => workshop.id === payload.id);
        if (!workshopIsInWorkshopsList) return;

        if (state.selectedWorkshop !== payload.id) {
            state.selectedWorkshop = payload.id;
            state.selectedTimeSlotSelfDeliverAndPickup = undefined;
        }
        if (payload.isFavourite) {
            state.favouriteWorkshop = payload.id;
        }
    }),
    setTimeSlot: action((state, payload) => {
        setTimeSlotForDeliveryType(state, state.deliveryType, payload);
    }),
    setTimeSlotForDeliveryType: action((state, payload) => {
        setTimeSlotForDeliveryType(state, payload.deliveryType, payload.timeSlot);
    }),
    setWorkshops: action((state, payload) => {
        state.workshops = payload;
    }),
    setCalendarShouldUpdate: action((state, payload) => {
        state.calendarShouldUpdate = payload;
    }),
    setShopToGetNearbyFrom: action((state, payload) => {
        state.shopToGetNearbyFrom = payload;
    }),
    cancelApiCalls: action((state) => {
        if (state.abortController) {
            state.abortController.abort();
        }
    }),
    createNewAbortController: action((state) => {
        state.abortController = new AbortController();
        state.workshops.forEach((x) => {
            x.loadingDays = false;
            x.availableDaysSelfDeliverAndPickup = [];
            x.availableDaysToStay = [];
            x.availableDaysServiceTooLong = [];
            x.availableDaysMobileService = [];
        });
        state.selectedTimeSlotSelfDeliverAndPickup = undefined;
        state.selectedTimeSlotToStay = undefined;
        state.selectedTimeSlotServiceTooLong = undefined;
        state.selectedTimeSlotMobileService = undefined;
    }),
    setIsLoadingSelectedWorkshop: action((state, payload) => {
        state.isLoadingSelectedWorkshop = payload;
    }),
    setIsLoadingForWorkshop: action((state, payload) => {
        const workshop = state.workshops.find((x) => x.id === payload.id);
        if (workshop) workshop.loadingDays = payload.loading;
    }),
    clearSelectedTimeSlots: action((state) => {
        state.selectedTimeSlotToStay = undefined;
        state.selectedTimeSlotMobileService = undefined;
        state.selectedTimeSlotSelfDeliverAndPickup = undefined;
        state.selectedTimeSlotServiceTooLong = undefined;
    }),
});

const bookingWorkshopThunks = (): WorkshopsThunks => ({
    getAvailableDaysThunk: thunk(async (actions, payload, { injections: { pushError }, getStoreState }) => {
        const storeState = getStoreState();

        if (payload.fullReload) {
            // Consider doing this in the initialization for getWorkshopsThunk
            storeState.workshops.forEach((x) => (x.loadingDays = true));
        }

        const currentWorkshop = storeState.workshops.find((x) => x.id === payload.apiPayload.workshopId);

        if (currentWorkshop) {
            actions.setIsLoadingForWorkshop({ id: currentWorkshop.id, loading: true });
        }

        storeState.abortController ??= new AbortController();

        const { signal } = storeState.abortController;
        const leadTime = payload.serviceTooLongLeadTime ? payload.serviceTooLongLeadTime(payload.apiPayload.workshopId) : 0;
        const mobileService = payload.mobileService && currentWorkshop ? payload.mobileService(currentWorkshop.id) : false;

        if (mobileService) {
            const availableDays = generateServiceTooLongAppointmentAvailability(
                leadTime,
                parseISO(payload.apiPayload.dateFrom),
                parseISO(payload.apiPayload.dateTo),
                payload.serviceTooLongHolidays
            );

            actions.setAvailableDays({
                deliveryType: DeliveryType.MobileService,
                params: [
                    {
                        id: payload.apiPayload.workshopId,
                        availableDays: availableDays,
                        concatToExistingDays: !payload.fullReload,
                    },
                ],
            });
        }

        if (payload.serviceTooLong) {
            const availableDays = generateServiceTooLongAppointmentAvailability(
                leadTime,
                parseISO(payload.apiPayload.dateFrom),
                parseISO(payload.apiPayload.dateTo),
                payload.serviceTooLongHolidays
            );

            actions.setAvailableDays({
                deliveryType: DeliveryType.ServiceTooLong,
                params: [
                    {
                        id: payload.apiPayload.workshopId,
                        availableDays: availableDays,
                        concatToExistingDays: !payload.fullReload,
                    },
                ],
            });
        } else {
            const availableDaysSelfDeliverAndPickupPromise = getWorkshopServiceAppointmentAvailability(
                {
                    ...payload.apiPayload,
                },
                signal
            );
            const availableDaysToStayPromise = getWorkshopServiceAppointmentAvailability(
                {
                    ...payload.apiPayload,
                    customerStays: true,
                },
                signal
            );
            const [[availableDaysSelfDeliverAndPickup, availableDaysSelfDeliverAndPickupError], [availableDaysToStay, availableDaysToStayError]] =
                await Promise.all([availableDaysSelfDeliverAndPickupPromise, availableDaysToStayPromise]);
            if (availableDaysSelfDeliverAndPickup && !availableDaysSelfDeliverAndPickupError) {
                actions.setAvailableDays({
                    deliveryType: DeliveryType.SelfDeliverAndPickup,
                    params: [
                        {
                            id: payload.apiPayload.workshopId,
                            availableDays: availableDaysSelfDeliverAndPickup.appointmentAvailabilities,
                            concatToExistingDays: !payload.fullReload,
                        },
                    ],
                });
            } else if (availableDaysSelfDeliverAndPickupError) {
                pushError(availableDaysSelfDeliverAndPickupError.errorType);
            }
            if (availableDaysToStay && !availableDaysToStayError) {
                actions.setAvailableDays({
                    deliveryType: DeliveryType.CustomerStays,
                    params: [
                        {
                            id: payload.apiPayload.workshopId,
                            availableDays: availableDaysToStay.appointmentAvailabilities,
                            concatToExistingDays: !payload.fullReload,
                        },
                    ],
                });
            } else if (availableDaysToStayError) {
                pushError(availableDaysToStayError.errorType);
            }
        }
        if (currentWorkshop) {
            actions.setIsLoadingForWorkshop({ id: currentWorkshop.id, loading: false });
        }
    }),

    getNearbyStoresAvailableDaysThunk: thunk(async (actions, payload, { injections: { pushError }, getStoreState }) => {
        const storeState = getStoreState();
        const currentWorkshop = storeState.workshops.find((x) => x.id === payload.apiPayload.workshopId);

        // Nearby stores
        if (currentWorkshop?.nearByStores) {
            if (!storeState.abortController) {
                storeState.abortController = new AbortController();
            }

            const { signal } = storeState.abortController;

            const storesToLoad = storeState.workshops
                .filter((ws) => currentWorkshop.nearByStores.some((store) => store.id === ws.id))
                .filter((ws) => ws.availableDaysSelfDeliverAndPickup.length === 0);

            for (const nearByStore of storesToLoad) {
                const leadTime = payload.serviceTooLongLeadTime ? payload.serviceTooLongLeadTime(nearByStore.id) : 0;
                const mobileService = payload.mobileService ? payload.mobileService(nearByStore.id) : false;
                if (mobileService) {
                    const availableDays = generateServiceTooLongAppointmentAvailability(
                        leadTime,
                        parseISO(payload.apiPayload.dateFrom),
                        parseISO(payload.apiPayload.dateTo),
                        payload.serviceTooLongHolidays
                    );

                    actions.setAvailableDays({
                        deliveryType: DeliveryType.MobileService,
                        params: [
                            {
                                id: nearByStore.id,
                                availableDays: availableDays,
                                concatToExistingDays: false,
                            },
                        ],
                    });
                }

                if (payload.serviceTooLong) {
                    const availableDays = generateServiceTooLongAppointmentAvailability(
                        leadTime,
                        parseISO(payload.apiPayload.dateFrom),
                        parseISO(payload.apiPayload.dateTo),
                        payload.serviceTooLongHolidays
                    );

                    actions.setAvailableDays({
                        deliveryType: DeliveryType.ServiceTooLong,
                        params: [
                            {
                                id: nearByStore.id,
                                availableDays: availableDays,
                                concatToExistingDays: false,
                            },
                        ],
                    });
                } else {
                    const availableDaysPromise = getWorkshopServiceAppointmentAvailability(
                        {
                            ...payload.apiPayload,
                            workshopId: nearByStore.id,
                        },
                        signal
                    );

                    const availableDaysToStayPromise = getWorkshopServiceAppointmentAvailability(
                        {
                            ...payload.apiPayload,
                            workshopId: nearByStore.id,
                            customerStays: true,
                        },
                        signal
                    );

                    const [[days, daysError], [daysToStay, daysToStayError]] = await Promise.all([availableDaysPromise, availableDaysToStayPromise]);

                    if (days && !daysError) {
                        actions.setAvailableDays({
                            deliveryType: DeliveryType.SelfDeliverAndPickup,
                            params: [
                                {
                                    id: nearByStore.id,
                                    availableDays: days.appointmentAvailabilities,
                                    concatToExistingDays: false,
                                },
                            ],
                        });
                    } else if (daysError) {
                        pushError(daysError.errorType);
                    }

                    if (daysToStay && !daysToStayError) {
                        actions.setAvailableDays({
                            deliveryType: DeliveryType.CustomerStays,
                            params: [
                                {
                                    id: nearByStore.id,
                                    availableDays: daysToStay.appointmentAvailabilities,
                                    concatToExistingDays: false,
                                },
                            ],
                        });
                    } else if (daysToStayError) {
                        pushError(daysToStayError.errorType);
                    }
                }
                actions.setIsLoadingForWorkshop({ id: nearByStore.id, loading: false });
            }
        }
    }),

    getNonPreferredStoreAvailableDaysThunk: thunk(async (actions, payload, { injections: { pushError }, getStoreState }) => {
        const today = new Date();

        const storeState = getStoreState();

        const currentWorkshop = storeState.workshops.find((x) => x.id === payload.apiPayload.workshopId);

        const currentNearbyStoreIds = currentWorkshop?.nearByStores.map((nearbyStore) => nearbyStore.id);

        const workshopList = storeState.workshops.filter(
            (otherWorkshop) => otherWorkshop.id !== currentWorkshop?.id && !currentNearbyStoreIds?.includes(otherWorkshop.id)
        );

        if (!storeState.abortController) {
            storeState.abortController = new AbortController();
        }
        const { signal } = storeState.abortController;

        for (const workshop of workshopList) {
            const leadTime = payload.serviceTooLongLeadTime ? payload.serviceTooLongLeadTime(workshop.id) : 0;
            const mobileService = payload.mobileService ? payload.mobileService(workshop.id) : false;
            if (mobileService) {
                const availableDays = generateServiceTooLongAppointmentAvailability(
                    leadTime,
                    parseISO(payload.apiPayload.dateFrom),
                    parseISO(payload.apiPayload.dateTo),
                    payload.serviceTooLongHolidays
                );

                actions.setAvailableDays({
                    deliveryType: DeliveryType.MobileService,
                    params: [
                        {
                            id: workshop.id,
                            availableDays,
                            concatToExistingDays: false,
                        },
                    ],
                });
            }

            if (payload.serviceTooLong) {
                const availableDays = generateServiceTooLongAppointmentAvailability(
                    leadTime,
                    parseISO(payload.apiPayload.dateFrom),
                    parseISO(payload.apiPayload.dateTo),
                    payload.serviceTooLongHolidays
                );
                actions.setAvailableDays({
                    deliveryType: DeliveryType.ServiceTooLong,
                    params: [
                        {
                            id: workshop.id,
                            availableDays,
                            concatToExistingDays: false,
                        },
                    ],
                });
            } else {
                const availableDaysPromise = getWorkshopServiceAppointmentAvailability(
                    {
                        ...payload.apiPayload,
                        workshopId: workshop.id,
                        dateFrom: formatDate(today, DateStyle.yyyy_mm_dd),
                        dateTo: formatDate(add(today, { months: 2 }), DateStyle.yyyy_mm_dd),
                    },
                    signal
                );

                const availableDaysToStayPromise = getWorkshopServiceAppointmentAvailability(
                    {
                        ...payload.apiPayload,
                        dateFrom: formatDate(today, DateStyle.yyyy_mm_dd),
                        dateTo: formatDate(add(today, { months: 2 }), DateStyle.yyyy_mm_dd),
                        workshopId: workshop.id,
                        customerStays: true,
                    },
                    signal
                );

                const [[availableDays, availableDaysError], [availableDaysToStay, availableDaysToStayError]] = await Promise.all([
                    availableDaysPromise,
                    availableDaysToStayPromise,
                ]);

                if (availableDays && !availableDaysError) {
                    actions.setAvailableDays({
                        deliveryType: DeliveryType.SelfDeliverAndPickup,
                        params: [
                            {
                                id: workshop.id,
                                availableDays: availableDays.appointmentAvailabilities,
                                concatToExistingDays: false,
                            },
                        ],
                    });
                } else if (availableDaysError) {
                    pushError(availableDaysError.errorType);
                }

                if (availableDaysToStay && !availableDaysToStayError) {
                    actions.setAvailableDays({
                        deliveryType: DeliveryType.CustomerStays,
                        params: [
                            {
                                id: workshop.id,
                                availableDays: availableDaysToStay.appointmentAvailabilities,
                                concatToExistingDays: false,
                            },
                        ],
                    });
                } else if (availableDaysToStayError) {
                    pushError(availableDaysToStayError.errorType);
                }
            }
            actions.setIsLoadingForWorkshop({ id: workshop.id, loading: false });
        }
    }),

    getWorkshopsThunk: thunk(async (actions, payload, { injections: { pushError } }) => {
        actions.setLoadingWorkshops(true);

        const [result, error] = await getWorkshops(payload);
        if (result && !error) {
            const workshops: Workshop[] = result.workshops.map(({ nearbyWorkshopIds, ...workshopResponse }) => ({
                ...workshopResponse,
                availableDaysSelfDeliverAndPickup: [],
                availableDaysToStay: [],
                availableDaysMobileService: [],
                availableDaysServiceTooLong: [],
                loadingDays: false,
                id: workshopResponse.id,
                nearByStores: result.workshops.filter((workshop) => nearbyWorkshopIds.includes(workshop.id)),
            }));

            actions.setWorkshops(workshops);
            if (payload.preferredWorkshopId) actions.setSelectedWorkshop({ id: payload.preferredWorkshopId, isFavourite: true });
        } else if (error) {
            pushError(error.errorType);
        }

        actions.setLoadingWorkshops(false);
    }),
});

export const BookingWorkshopsStore = createContextStoreWithRuntimeModel<WorkshopsStore, WorkshopsState, WorkshopsInjections>(
    () => ({
        ...bookingWorkshopDefaultState(),
        ...bookingWorkshopActions(),
        ...bookingWorkshopThunks(),
    }),
    { name: 'Workshops' }
);
