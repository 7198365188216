import React, { VFC } from 'react';
import { StyledBreadcrumbList, StyledBreadCrumb, StyledPageName } from './breadcrumb.styled';
import Link from 'next/link';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { cmsUrlWithoutSiteId } from '../../../utils/helpers';

type BreadcrumbProps = {
    breadcrumbs?: Array<sharedTypes.Breadcrumb>;
    currentPageName: string;
    extraInfo?: string;
    isCarsaver?: boolean;
};

function processBreadCrumbs(pageName: string, extraInfo?: string) {
    if (extraInfo) {
        return ` ${extraInfo}`;
    }

    return pageName;
}

export const BreadcrumbMemo: VFC<BreadcrumbProps> = ({ breadcrumbs, currentPageName, extraInfo, isCarsaver }) => {
    if (!currentPageName || currentPageName.trim().length === 0) {
        return null;
    }

    return (
        <StyledBreadcrumbList isCarsaver={isCarsaver}>
            {breadcrumbs?.map((x) => {
                let url = '';

                try {
                    url = new URL(x.url).pathname;
                } catch (e) {
                    url = cmsUrlWithoutSiteId(x.url);
                }

                return (
                    <React.Fragment key={`breadcrumb-${x.name}`}>
                        <Link href={url} passHref={true}>
                            <StyledBreadCrumb isActive={true}>{x.name}</StyledBreadCrumb>
                        </Link>{' '}
                        /{' '}
                    </React.Fragment>
                );
            })}
            {breadcrumbs && breadcrumbs.length > 0 ? <StyledPageName>{processBreadCrumbs(currentPageName, extraInfo)}</StyledPageName> : null}
        </StyledBreadcrumbList>
    );
};

export const Breadcrumb = React.memo(BreadcrumbMemo);
