import { FC, useMemo } from 'react';
import { SvgIcon } from '../svg-icon';
import {
    WrapperItemWrapper,
    WorkshopHeader,
    WorkshopName,
    WorkshopAddress,
    WorkshopBody,
    DateHeader,
    DateInfo,
    LatestVisit,
    LatestVisitText,
    DateWrapper,
    DisabledText,
} from './workshop-card.styled';
import { IWorkshopCard } from './workshop-card.props';
import { Spinner } from '../spinner';
import { ShiftBy } from '../shift-by';
import { DateStyle, formatDate, getAvailableDaysByDeliveryType } from '../../../utils/helpers';
import { compareAsc } from 'date-fns';
import { BookingWorkshopsStore } from '../../../lib/state/booking/workshops';

export const WorkshopCard: FC<IWorkshopCard> = ({ workshop, setAsSelected, isSelected, isLoading, disabled, lastVisited, workshopContent }) => {
    const { id, name, address } = workshop;

    const deliveryType = BookingWorkshopsStore.useStoreState((state) => state.deliveryType);

    const nextAvailableDate = useMemo(() => {
        const availableDays = getAvailableDaysByDeliveryType(workshop, deliveryType);

        const sortedAvailableDays = availableDays.sort((a, b) => compareAsc(a.date, b.date));
        const firstDateWithAvailableTimeSlot = sortedAvailableDays?.find((x) => x.timeSlots.some((timeSlot) => timeSlot.available));
        return firstDateWithAvailableTimeSlot?.date;
    }, [deliveryType, workshop]);

    return (
        <WrapperItemWrapper isSelected={isSelected} onClick={() => !disabled && setAsSelected(id)} disabled={disabled}>
            <WorkshopHeader>
                <WorkshopName disabled={disabled}>{name}</WorkshopName>
                {lastVisited && (
                    <LatestVisit>
                        <LatestVisitText>
                            {workshopContent.preferredWorkshopText ? workshopContent.preferredWorkshopText : 'Sidste besøgt'}
                        </LatestVisitText>
                        <SvgIcon iconName="car/star" />
                    </LatestVisit>
                )}
            </WorkshopHeader>
            {disabled ? (
                <DisabledText>{workshopContent.mobileServiceWorkshopDisabledExplanationText}</DisabledText>
            ) : (
                <WorkshopBody>
                    <>
                        <WorkshopAddress>{address}</WorkshopAddress>

                        <DateWrapper>
                            <DateHeader>Næste ledige dato:</DateHeader>

                            {isLoading ? (
                                <ShiftBy x={10} y={5}>
                                    <Spinner variant="light" size="small" />
                                </ShiftBy>
                            ) : (
                                <DateInfo>
                                    {nextAvailableDate
                                        ? formatDate(nextAvailableDate, DateStyle.simple_danish)
                                        : workshopContent.longWaitingTimeText ?? 'Mere end en måned'}
                                </DateInfo>
                            )}
                        </DateWrapper>
                    </>
                </WorkshopBody>
            )}
        </WrapperItemWrapper>
    );
};
