import styled from 'styled-components';

export const StyledFooterShape = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    overflow: hidden;
`;

export const Triangle = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-right: 100vw solid transparent;
    border-bottom: 40px solid ${({ theme }) => theme.palette.footer.background};
`;
