import { FC, useMemo } from 'react';
import { DeliveryType } from '../../../../../lib';
import { umbraco } from '../../../../../lib/api';
import { BookingWorkshopsStore } from '../../../../../lib/state/booking/workshops';
import { DateStyle, formatDate, getPickupTime, getTimeSlotEnd } from '../../../../../utils/helpers';
import { SubmittedInfo } from '../../../../shared/submitted-info';
import { CompletedWrapper, StyledMobileServiceInfoWrapper } from './workshop-completed.styled';

type Props = {
    workshopContent: umbraco.BookingStepWorkshop;
};

export const CompletedWorkshop: FC<Props> = ({ workshopContent }) => {
    const { selectedDate, selectedWorkshop, workshops, deliveryType, selectedTimeSlot } = BookingWorkshopsStore.useStoreState((state) => state);

    const workshop = useMemo(() => workshops.find(({ id }) => id === selectedWorkshop), [workshops, selectedWorkshop]);

    const pickUpMethodDescription = useMemo(() => {
        if (!selectedTimeSlot) return '';

        switch (deliveryType) {
            case DeliveryType.ByVendor:
                return workshopContent.pickupMethodDescription;
            case DeliveryType.CustomerStays:
                return `${workshopContent.timeSlotPickupHeader} ${formatDate(getTimeSlotEnd(selectedTimeSlot), DateStyle.HH_mm)}`;
            case DeliveryType.SelfDeliverAndPickup:
            default:
                return `${workshopContent.timeSlotPickupHeader} ${formatDate(getPickupTime(selectedTimeSlot.startDateTime), DateStyle.HH_mm)}`;
        }
    }, [deliveryType, selectedTimeSlot, workshopContent.pickupMethodDescription, workshopContent.timeSlotPickupHeader]);

    const getTime = (): string => {
        return selectedTimeSlot ? formatDate(selectedTimeSlot.startDateTime, DateStyle.HH_mm) : '';
    };

    const hasCustomDeliverySummary = useMemo(() => {
        return deliveryType === DeliveryType.ByVendor || deliveryType === DeliveryType.MobileService;
    }, [deliveryType]);

    return (
        <CompletedWrapper>
            <SubmittedInfo label={workshopContent.workshopNameLabel} text={workshop?.name ?? ''} />
            <SubmittedInfo label={workshopContent.selectedDateLabel} text={formatDate(selectedDate, DateStyle.dk_full_text)} />

            {!hasCustomDeliverySummary && <SubmittedInfo label={workshopContent.deliveryTimeLabel} text={getTime()} />}
            {deliveryType === DeliveryType.MobileService ? (
                <StyledMobileServiceInfoWrapper>
                    <SubmittedInfo
                        label={workshopContent.mobileServiceSummaryHeader ?? ''}
                        text={workshopContent.mobileServiceSummaryText ?? ''}
                        columnSpan={2}
                    />
                </StyledMobileServiceInfoWrapper>
            ) : (
                <SubmittedInfo label={workshopContent.pickupMethodLabel} text={pickUpMethodDescription} />
            )}
        </CompletedWrapper>
    );
};
