import styled, { css } from 'styled-components';
import { device, queryTarget } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { getCmsImageUrl } from '../../../utils/helpers';
import { MINIMAL_SITE_HEADER_HEIGHT } from '../../../lib/enums';

export const StyledHeroSplitSpot = styled.section<{ bgColor?: string }>`
    background-color: ${(props) => (props.bgColor ? '#' + props.bgColor : 'transparent')};
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    height: auto;
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
`;

export const StyledContentWrapper = styled.div<{ hasImage: boolean }>`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: none;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;

    @media ${device.laptop} {
        display: flex;
    }

    ${(props) =>
        props.hasImage &&
        css`
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background-color: rgba(0, 0, 0, 0.2);
            }

            @media ${device.laptop} {
                &::before {
                    display: none;
                }
            }
        `}

    @media ${device.tablet} {
        padding: 0 30px;
    }
`;

export const StyledDeviceContentWrapper = styled(StyledContentWrapper)`
    display: flex;
    @media ${device.laptop} {
        display: none;
    }
`;

export const StyledTextWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: auto;
    position: relative;
    z-index: 2;

    @media ${device.tablet} {
        align-items: center;
        justify-content: flex-start;
        min-height: 660px;
        width: 60%;
    }

    @media ${device.laptop} {
        width: calc(50% - 50px);
    }
`;

export const StyledTextContent = styled.div`
    max-width: 100%;
    max-width: 450px;
    padding: calc(${MINIMAL_SITE_HEADER_HEIGHT.MOBILE}px + 120px) 20px 48px;
    white-space: pre-line;
    position: relative;
    z-index: 1;

    @media ${device.tablet} {
        max-width: 390px;
        padding: 131px 0 70px;
    }
`;

export const StyledSpotHeader = styled(SpotHeader)<{ textColorSmall?: string; textColorLarge?: string }>`
    margin-bottom: 40px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    color: ${(props) => (props.textColorSmall ? `#${props.textColorSmall}` : ({ theme }) => theme.palette.text.primary)};
    white-space: pre-line;

    @media ${device.laptop} {
        color: ${(props) => (props.textColorLarge ? `#${props.textColorLarge}` : ({ theme }) => theme.palette.text.primary)};
    }
`;

export const StyledText = styled.p<{ textColorSmall?: string; textColorLarge?: string }>`
    color: ${(props) => (props.textColorSmall ? `#${props.textColorSmall}` : ({ theme }) => theme.palette.text.primary)};
    white-space: pre-line;

    @media ${device.laptop} {
        color: ${(props) => (props.textColorLarge ? `#${props.textColorLarge}` : ({ theme }) => theme.palette.text.primary)};
    }
`;

export const StyledSmallDevicesOnly = styled.div`
    @media ${device.laptop} {
        display: none;
    }
`;

export const StyledLargerDevicesOnly = styled.div`
    display: none;
    @media ${device.laptop} {
        display: block;
    }
`;

export const StyledCtas = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 30px;

    > button {
        width: 100%;

        @media ${device.mobileL} {
            width: auto;
        }
    }

    @media ${device.tablet} {
        gap: 15px;
    }
`;

export const StyledImageWrapper = styled.div<{ imageUrl?: string; bgColor?: string }>`
    background-image: ${(props) => (props.imageUrl ? `url(${getCmsImageUrl(props.imageUrl)})` : 'none')};
    background-size: cover;
    background-position: 70% center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    overflow: hidden;

    @media ${device.tablet} {
        background-position: center right;
    }

    @media ${device.laptop} {
        left: calc(50% - 50px);
        width: calc(50vw + 50px);
    }

    @media ${device.desktop} {
        width: calc(calc(${queryTarget.desktop}px / 2) + 50px);
    }
`;

export const StyledSpacingPostSpot = styled.div`
    height: 32px;
    background-color: #f2f2f2;

    @media ${device.laptop} {
        height: 70px;
    }
`;
