import { FC } from 'react';
import { ISubmittedInfo } from './submitted-info.props';
import { Wrapper, StyledLabel, StyledText } from './submitted-info.styled';

export const SubmittedInfo: FC<ISubmittedInfo> = ({ label, text, overrideMaxWidth, columnSpan }) => {
    return (
        <Wrapper overridePadding={!!overrideMaxWidth} columnSpan={columnSpan}>
            <StyledLabel>{label}</StyledLabel>
            <StyledText overrideMaxWidth={!!overrideMaxWidth || !!columnSpan}>{text}</StyledText>
        </Wrapper>
    );
};
